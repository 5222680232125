define("discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-first-post", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/object/computed", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _component2, _object, _computed, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @topic.first_tracked_post}}
    <DButton
      class="first-tracked-post"
      @icon="circle-arrow-up"
      @title="group_tracker.first_post"
      @disabled={{this.disabled}}
      @action={{action "jumpToFirstTrackedPost"}}
    />
  {{/if}}
  */
  {
    "id": "C0QZWpWp",
    "block": "[[[41,[30,1,[\"first_tracked_post\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"first-tracked-post\"]],[[\"@icon\",\"@title\",\"@disabled\",\"@action\"],[\"circle-arrow-up\",\"group_tracker.first_post\",[30,0,[\"disabled\"]],[28,[37,2],[[30,0],\"jumpToFirstTrackedPost\"],null]]],null],[1,\"\\n\"]],[]],null]],[\"@topic\"],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-first-post.hbs",
    "isStrictMode": false
  });
  class GroupTrackerFirstPost extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "postId", [(0, _computed.readOnly)("args.topic.currentPost")]))();
    #postId = (() => (dt7948.i(this, "postId"), void 0))();
    get disabled() {
      const topic = this.args.topic;
      if (topic.first_tracked_post) {
        const jump_target = topic.first_tracked_post.jump_target || topic.first_tracked_post.post_number;
        return jump_target >= this.postId;
      }
      return null;
    }
    jumpToFirstTrackedPost() {
      const topic = this.args.topic;
      if (topic.first_tracked_post) {
        const jump_target = topic.first_tracked_post.jump_target || topic.first_tracked_post.post_number;
        _url.default.jumpToPost(jump_target);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "jumpToFirstTrackedPost", [_object.action]))();
  }
  _exports.default = GroupTrackerFirstPost;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupTrackerFirstPost);
});