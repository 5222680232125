define("discourse/plugins/discourse-group-tracker/discourse/templates/connectors/before-topic-progress/group-tracker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <GroupTrackerNav @topic={{this.model}} @jumpToPost={{this.jumpToPost}} />
  */
  {
    "id": "45cOwlmM",
    "block": "[[[8,[39,0],null,[[\"@topic\",\"@jumpToPost\"],[[30,0,[\"model\"]],[30,0,[\"jumpToPost\"]]]],null]],[],false,[\"group-tracker-nav\"]]",
    "moduleName": "discourse/plugins/discourse-group-tracker/discourse/templates/connectors/before-topic-progress/group-tracker.hbs",
    "isStrictMode": false
  });
});